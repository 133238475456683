/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2ZmYTMwMCIsIj9lcjwjZmZlM2IzIiwiO2VyPCNmZjg4MDB$LCIlPmBePCM2Yzc1N2QiLCI~ZXI8I2QzZDZkOCIsIjtlcjwjNGY1ODYwfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMzMzMzMzMiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
     

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #333333;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat.core();

// Theme Config

body {
  --primary-color: #ffa300;
  --primary-lighter-color: #ffe3b3;
  --primary-darker-color: #ff8800;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}   
$mat-primary: (
  main: #ffa300,
  lighter: #ffe3b3,
  darker: #ff8800,
  200: #ffa300, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #6c757d;
  --accent-lighter-color: #d3d6d8;
  --accent-darker-color: #4f5860;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}   
$mat-accent: (
  main: #6c757d,
  lighter: #d3d6d8,
  darker: #4f5860,
  200: #6c757d, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}   
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}



// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}



// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $dingg-artifacts-primary: mat.define-palette(mat.$indigo-palette);
// $dingg-artifacts-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $dingg-artifacts-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $dingg-artifacts-theme: mat.define-light-theme((
//   color: (
//     primary: $dingg-artifacts-primary,
//     accent: $dingg-artifacts-accent,
//     warn: $dingg-artifacts-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($dingg-artifacts-theme);

// /* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




//Helper
.spacer {
  flex: 1 1 auto;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

.btn-outline-primary {
  --bs-btn-color: #ffa300 !important;
  --bs-btn-border-color: #ffa300 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #ffa300 !important;
  --bs-btn-hover-border-color: #ffa300 !important;
  --bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #ffa300 !important;
  --bs-btn-active-border-color: #ffa300 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #ffa300 !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: #ffa300 !important;
  --bs-gradient: none !important;
}

.modal {
  --bs-modal-zindex: 999 !important;
}
.modal-backdrop{
  z-index: 999 !important;
}
.center {
  place-content: center;
  align-items: center;
}
.link {
  cursor: pointer;
}
.align-center{
  align-items: center;
}

.mat-mdc-form-field-bottom-align {
  &:before{
    content: none !important;
  }
}

.chip-container {
  min-width: 450px !important;
  .mat-mdc-form-field-infix {
    display: flex;
    padding: 0px !important;
    place-content: center;
    align-items: center;
    .mdc-evolution-chip-set {
      width: 350px;
      overflow: auto;
      display: flex;
      .mdc-evolution-chip-set__chips{
        flex-wrap: nowrap;
      }
    }
  }
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';


.modal-container{
   
  .mat-form-field-underline{
      height: 0px !important;
      display: none;
  }
  .mat-form-field-outline{
      display: none !important;
  }
  .mat-form-field-infix{
      border-top: 0.0em solid transparent;
      padding: 0.7375em 0;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex{
      background-color: transparent;
  }
  .mat-form-field-outline{
      display: inherit !important;
  }
  .mat-form-field-flex{
      background-color: rgb(36 177 135 / 3%) !important;
      transform: scale3d(1, 1.0001, 1);
      border: 1px solid $primary;
      border-radius: 4px;
  } 
  .mat-form-field-appearance-legacy .mat-form-field-wrapper{
      padding-bottom: 0em;
  }
  .mat-form-field-appearance-legacy .mat-form-field-label {
      top: 1.58125em;
      padding: 0px 10px;
  }

}
.loader{
  display: flex;
  place-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.category{
  width: 100%;
  padding: 20px;
  margin-top: 5rem;
  border-radius: 10px;
  background: #fff;
  .header {
      display: flex;
      flex-direction: row;
      place-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      .title{
        font-size: 18px;
        font-weight: normal;
        margin: 0px;
        text-transform: uppercase;
        flex: 1;
      }
      .link {
          font-size: 14px;
          text-decoration: underline;
          color: var(--active-link);
      }
  }
  
}
.content-space {
  background: rgb(248, 249, 250);
  overflow: auto;
  min-height: calc(100% - 65px) !important;
  padding: 20px;
  padding-bottom: 5rem;
  .container {
    display: flex;
    flex-direction: column;
  }
}



.form-card {
  margin-bottom: 0;
  position: relative;
  margin: 20px 0px;

  figure {
      position: relative;
      padding: 20px;
      border-bottom: 1px solid #ddd;
      min-height: 200px;
      display: flex;
      place-content: center;
      align-items: center;
      margin-bottom: 0px;
      cursor: pointer;
      flex-direction: column;
      text-align: center;

      h3 {
          font-size: 18px;
          margin-top: 50px;
      }

      p {
          font-size: 14px;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s linear;
      }

      &:hover {
          p {
              opacity: 1;
              visibility: visible;
          }
      }
  }

  .template-content {
      padding: 20px;
      display: flex;
      place-content: center;
      align-items: center;

      h3 {
          font-size: 16px;
          font-weight: normal;
          margin: 0px;
      }
  }
}

.menu {
  display: inline-flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 280px;
  background-color: rgba(255, 255, 255);
  padding: 6px 0;
}

.menu-item,
.standalone-trigger {
  background-color: transparent;
  cursor: pointer;
  border: none;

  user-select: none;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
}

.menu-item:hover {
  background-color: rgb(208, 208, 208);
}

.menu-item:active {
  background-color: rgb(170, 170, 170);
}

.standalone-item {
  background-color: rgb(239, 239, 239);
}

.standalone-item:hover {
  background-color: rgb(208, 208, 208);
}

.standalone-item[aria-expanded='true'] {
  background-color: rgb(208, 208, 208);
}
.mt-50{
  margin-top: 50px;
}
.toast-container {
  position: fixed !important;
}

.cdk-header-cell {
  min-width: 200px;
}

@media print {
  body * {
    visibility:hidden;
  }
  #printable, #printable * {
    visibility:visible;
  }
  /* Hide placeholder text in print */
  ::-webkit-input-placeholder {
    color: transparent !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent !important;
  }
  :-ms-input-placeholder {
    color: transparent !important;
  }

  /* Hide placeholder text in print for Angular Material input boxes */
  mat-form-field .mat-form-field-label-wrapper .mat-placeholder {
    visibility: hidden;
  }

  #printable {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    .print-button {
      display: none;
    }

  }
  .chapter-list {
    display: relative;
    display: block;
  }
  .question-card {
    page-break-inside: avoid !important;  
    page-break-before: always !important;
    page-break-after: always !important;
    display: block !important;
    position: relative;
  }
}
.tags {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 12px;
  margin-bottom: 30px;
  .tag {
      border: 1px solid #eee;
      border-radius: 30px;
      padding: 10px 15px;
      white-space: nowrap;
      background: #eee;
      cursor: pointer;
  }
}



.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}

.ck-content h3.category {
	font-family: 'Oswald';
	font-size: 20px;
	font-weight: bold;
	color: #555;
	letter-spacing: 10px;
	margin: 0;
	padding: 0;
}

.ck-content h2.document-title {
	font-family: 'Oswald';
	font-size: 50px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	border: 0;
}

.ck-content h3.document-subtitle {
	font-family: 'Oswald';
	font-size: 20px;
	color: #555;
	margin: 0 0 1em;
	font-weight: bold;
	padding: 0;
}

.ck-content p.info-box {
	--background-size: 30px;
	--background-color: #e91e63;
	padding: 1.2em 2em;
	border: 1px solid var(--background-color);
	background: linear-gradient(
			135deg,
			var(--background-color) 0%,
			var(--background-color) var(--background-size),
			transparent var(--background-size)
		),
		linear-gradient(
			135deg,
			transparent calc(100% - var(--background-size)),
			var(--background-color) calc(100% - var(--background-size)),
			var(--background-color)
		);
	border-radius: 10px;
	margin: 1.5em 2em;
	box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
	font-family: 'Oswald';
	font-style: normal;
	float: right;
	width: 35%;
	position: relative;
	border: 0;
	overflow: visible;
	z-index: 1;
	margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
	content: '“';
	position: absolute;
	top: -37px;
	left: -10px;
	display: block;
	font-size: 200px;
	color: #e7e7e7;
	z-index: -1;
	line-height: 1;
}

.ck-content blockquote.side-quote p {
	font-size: 2em;
	line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
	font-size: 1.3em;
	text-align: right;
	color: #555;
}

.ck-content span.marker {
	background: yellow;
}

.ck-content span.spoiler {
	background: #000;
	color: #000;
}

.ck-content span.spoiler:hover {
	background: #000;
	color: #fff;
}

.ck-content pre.fancy-code {
	border: 0;
	margin-left: 2em;
	margin-right: 2em;
	border-radius: 10px;
}

.ck-content pre.fancy-code::before {
	content: '';
	display: block;
	height: 13px;
	background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
	margin-bottom: 8px;
	background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
	background: #272822;
	color: #fff;
	box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
	background: #dddfe0;
	color: #000;
	box-shadow: 5px 5px 0 #b3b3b3;
}